// const imagesPrefix =
//   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/pages";
import team1 from "assets/images/tehsil-menecerleri-forumu.jpg";
import team2 from "assets/images/tehsilmenecerleriforumu.jpg";
import team3 from "assets/images/tmfblog1.jpg";
const newsdata = [
  {
    // image: `${imagesPrefix}/team-1.jpg`,
    image: team1,
    name: "Azərbaycanda ilk: Təhsil Menecerləri Forumu keçirilib",
    route: "/menecerlerin-forumu-kecirilib",
  },
  {
    image: team2,
    name: "Təhsil Menecerlərinin Forumu keçirilmişdir",
    route: "/menecerlerin-forumu-kecirildi",
  },
  {
    image: team3,
    name: "/Azərbaycanda ilk dəfə Təhsil Menecerləri Forumu keçiriləcək",
    route: "/menecerlerin-forumu-kecirilecek",
  },
];
export default newsdata;
