import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import { Helmet } from "react-helmet";
import bgImage from "assets/images/tmfelaqe.jpg";
import emailjs from "emailjs-com";

function ContactUs() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs;
    emailjs
      .sendForm(
        "service_8ilceeo", // EmailJS service ID-nizi buraya yazın
        "template_ihbs1ir", // EmailJS template ID-nizi buraya yazın
        form.current,
        "r7e5cp9LgCS-SUBOW" // EmailJS user ID-nizi buraya yazın
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Mesajınız uğurla göndərildi!");
          resetForm();
        },
        (error) => {
          console.log(error.text);
          alert("Mesaj göndərilərkən xəta baş verdi.");
        }
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      name: "",
      phone: "",
      message: "",
    });
  };

  return (
    <>
      <Helmet>
        <title>Əlaqə - Təhsil menecerlərinin forumu</title>
        <meta
          name="description"
          content="Hörmətli direktorlar, direktor müavinləri, müəllimlər, özəl təhsil sektorunda çalıșanlar və gələcəyin təhsil menecerləri,"
        />
      </Helmet>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/qeydiyyat",
            label: "qeydiyyatdan keç",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={4}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Bizimlə Əlaqə
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Hörmətli direktorlar, direktor müavinləri, müəllimlər, özəl təhsil sektorunda
                çalıșanlar və gələcəyin təhsil menecerləri,
              </MKTypography>
              <MKBox
                ref={form}
                width="100%"
                component="form"
                onSubmit={sendEmail}
                method="post"
                autoComplete="off"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Ad, Soyad"
                      InputLabelProps={{ shrink: true }}
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="text"
                      variant="standard"
                      label="Nömrə"
                      InputLabelProps={{ shrink: true }}
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="Mesajınızı qeyd edin!"
                      placeholder="Şikayət və təkliflərinizi qeyd edə bilərsiniz"
                      InputLabelProps={{ shrink: true }}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Göndər
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.3393761928646!2d49.84298717652973!3d40.40133195657564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307dabaf46eccb%3A0x8ee4131cc5159817!2zSMmZZMmZZiDFnmlya8mZdGzJmXIgUXJ1cHU!5e0!3m2!1str!2saz!4v1723451934779!5m2!1str!2saz"
            width="95%"
            height="450"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
