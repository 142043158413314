// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

// TMF React components
import MKTypography from "components/MKTypography";

// Images
// import logoCT from "assets/images/logo-ct-dark.png";
import logoCT from "assets/images/tmf.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "TMF",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.instagram.com/hedefglobaltehsil",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/hedefglobaltehsil",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.facebook.com/hedefglobalgroup",
    },
  ],
  menus: [
    {
      name: "TMF",
      items: [
        { name: "Haqqımızda" },
        { name: "Müəllif" },
        { name: "Mövzular" },
        { name: "Xəbərlər" },
      ],
    },
    {
      name: "Əlaqə",
      items: [
        { name: "tel: 077 220 44 22" },
        { name: "tel: 077 233 33 77" },
        { name: "mail: global@hedefgroup.az" },
      ],
    },
    {
      name: "Mövzular",
      items: [
        { name: "Uğurlu təhsil konsepsiyaları" },
        { name: "Təhsildə idarəetmənin strategiyası" },
        { name: "Məktəb idarəetməsindəki yanlışlıqlar" },
        { name: "BAŞ OFİS" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Bütün hüquqlar qorunur. &copy; {date}{" "}
      <MKTypography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        TƏHSİL MENECERLƏRİNİN I FORUMU
      </MKTypography>
      .
    </MKTypography>
  ),
};
