import React from "react";
// import { Link } from "react-router-dom";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "pages/LandingPages/Author/sections/Footer";
import routes from "routes";
import bgImage from "assets/images/tmf.webp";
import Pages from "pages/Presentation/sections/Pages";
// import Container from "@mui/material/Container";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Grid from "@mui/material/Grid";
import foto from "assets/images/macbook.png";
import { Helmet } from "react-helmet";
import additionalImage from "assets/images/tehsilmenecerleriforumu.jpg";

function Blogone() {
  return (
    <>
      <Helmet>
        <title>“Təhsil Menecerlərinin Forumu” keçirilmişdir</title>
        <meta name="description" content="“Təhsil Menecerlərinin Forumu” keçirilmişdir" />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/qeydiyyat",
          label: "qeydiyyatdan keç",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="11rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <MKBox display="flex" justifyContent="center" p={3}>
          <Grid container item xs={12} md={12} justifyContent="center">
            <MKBox mb={5} sx={{ maxWidth: "100%" }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6}>
                  <img
                    src={additionalImage}
                    alt="Additional"
                    style={{ width: "90%", borderRadius: "8px" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    image={foto}
                    title="“Təhsil Menecerlərinin Forumu” keçirilmişdir"
                    description={
                      <MKBox sx={{ textAlign: "justify" }}>
                        2023-cü il noyabrın 19-da Bakı şəhərindəki 20 nömrəli məktəbdə Xəzər
                        Universiteti və “Dünya” məktəbinin tərəfdaşlığı, Hədəf Şirkətlər Qrupunun
                        təşkilatçılığı ilə “Təhsil Menecerlərinin Forumu” keçirilmişdir. Tədbirdə
                        məktəbin rəhbərliyi və müəllimləri, Xəzər Universitetinin rəhbərliyi və
                        əməkdaşları iştirak etmişlər. Forumda Xəzər Universitetinin təsisçisi,
                        Direktorlar və Qəyyumlar Şurasının sədri Prof.Akad. Hamlet İsaxanlı
                        “Təhsildə keyfiyyət və idarəetmə problemləri” mövzusunda geniş məruzə
                        etmişdir. Tədbirdə Xəzər Universitetinə və Prof. Akad. Hamlet İsaxanlının
                        yaradıcılığına həsr olunan kitablar da forumun sərgi stendlərindən birində
                        nümayiş olunmuşdur. Forumun keçirilməsində əsas məqsəd təhsil sistemi və
                        xidmətlərinin effektiv təşkilinə, kadrların idarə olunmasına, təhsildə
                        informasiya texnologiyalarının tətbiqi və istifadəsinə, eləcə də keyfiyyətli
                        tədris üsulu və dünyaya inteqrasiya istiqamətində doğru sayılan
                        yanlışlıqları müəyyənləşdirməyə, dövlət və özəl təhsil müəssisələri arasında
                        işgüzar əlaqələrin və kommunikasiyaların möhkəmləndirilməsinə xidmət edən
                        platforma yaradılmasıdır.
                      </MKBox>
                    }
                  />
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </MKBox>
        <Pages />
        <Footer />
      </MKBox>
    </>
  );
}

export default Blogone;
