import React from "react";
// import { Link } from "react-router-dom";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "pages/LandingPages/Author/sections/Footer";
import routes from "routes";
import bgImage from "assets/images/tmf.webp";
import Pages from "pages/Presentation/sections/Pages";
// import Container from "@mui/material/Container";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Grid from "@mui/material/Grid";
import foto from "assets/images/macbook.png";
import { Helmet } from "react-helmet";
import additionalImage from "assets/images/tehsil-menecerleri-forumu.jpg";

function BlogTwo() {
  return (
    <>
      <Helmet>
        <title>“Azərbaycanda ilk: Təhsil Menecerləri Forumu keçirilib.</title>
        <meta
          name="description"
          content="Noyabrın 19-da yerli və xarici təhsil müəssisəsi rəhbərlərinin iştirakı ilə Təhsil Menecerləri Forumu keçirilib."
        />
      </Helmet>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/qeydiyyat",
          label: "qeydiyyatdan keç",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="11rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <MKBox display="flex" justifyContent="center" p={3}>
          <Grid container item xs={12} md={12} justifyContent="center">
            <MKBox mb={5} sx={{ maxWidth: "100%" }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6}>
                  <img
                    src={additionalImage}
                    alt="Additional"
                    style={{ width: "90%", borderRadius: "8px" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    image={foto}
                    title="Azərbaycanda ilk: Təhsil Menecerləri Forumu keçirilib"
                    description={
                      <MKBox sx={{ textAlign: "justify" }}>
                        Noyabrın 19-da yerli və xarici təhsil müəssisəsi rəhbərlərinin iştirakı ilə
                        Təhsil Menecerləri Forumu keçirilib. senet.az xəbər verir ki, giriş nitqi
                        ilə çıxış edən Hədəf Şirkətlər Qrupunun baş direktoru Şəmil Sadiq tədbirin
                        məqsədi, perspektivləri barədə məlumat verib, bu sahədə atılan addımlar və
                        problemlərdən söz açıb, onların həlli yollarına diqqət çəkib. Deputatlar
                        Fazil Mustafa, Ceyhun Məmmədov, Dövlət Gömrük Komitəsi Akademiyasının rəisi
                        Qulu Novruzov Təhsil Menecerləri Forumunun Azərbaycanda ilk dəfə
                        keçirildiyindən, belə tədbirlərin təhsil sahəsi üçün açdığı imkanlardan söz
                        açıblar. Bundan başqa çıxışçılar istər məktəblər, istər kollec və
                        universitetlərdə təhsil sisteminin çatışmazlıqlarından, mövcud problemlərin
                        həlli yollarından danışıb, bunun üçün əlverişli şərait yaradılmasının
                        vacibliyindən bəhs ediblər. Xəzər Universiteti Direktorlar və Qəyyumlar
                        Şurasının sədri, professor Hamlet İsaxanlı isə “Təhsildə idarəetmə və
                        keyfiyyət” mövzusunda çıxış edib. Keyfiyyətli təhsilin keyfiyyətli gələcəyə
                        yol açacağından, əvvəl bilmək istəyən, daha sonra öyrənməyə can atan nəsil
                        yetişdirməyin vacibliyindən danışıb. “Azərbaycanda təhsil idarəçiliyi:
                        reallıqlar və perspektivlər” adlı mövzu qoyan Ceyhun Məmmədov Azərbaycan
                        təhsilinin hazırkı vəziyyəti ilə bağlı qayğılarından, perspektivləri
                        dəyərləndirmənin mümkünlüyündən, təhsil işçilərinin vahid nəticə üçün ortaq
                        qənaətdə birləşməli olduqlarından söz açıb. Biznes və karyera məsləhətçisi
                        Fəxri Ağayev “Şirkət nəticələrində rəhbərin rolu və məsuliyyəti”ndən
                        danışıb. O qeyd edib ki, işə götürülən işçinin potensialı və gördüyü işin
                        keyfiyyəti rəhbərin məsuliyyəti deyil. Amma bir il sonra da nəticə
                        dəyişmirsə, bu, təkcə işçi ilə yox, rəhbərlə də bağlı problemdir. Hədəf
                        Şirkətlər Qrupunun təsisçisi Şəmil Sadiq “Təhsildə Hədəf konsepti”ndən söz
                        açıb, yeni tipli məktəbin yaradılmasının, milliliyi qorumaqla bəşəri
                        yeniliklərin tətbiqinin mümkünlüyündən danışıb. “CIBS Europe” təlim
                        mərkəzinin təsisçisi Təbriz Hacınski “Təhsil menecerliyində NLP” mövzusu ilə
                        danışıqların idarə olunmasının, bunun üçün strategiyanın, səs tonunun,
                        sözlərin düzgün seçilməyinin vacibliyini dilə gətirib. “Azərbaycan İnsan
                        Resursları Assosiasiyası” İctimai Birliyinin təsisçisi Nərgiz Seyidzadə
                        “Ofis başlarında dəyişim”dən danışıb, işçilərin və rəhbərliyin bölündüyü
                        qruplardan, iş yerində düzgün hesab olunan yanlışlardan, işçilərə fərdi
                        yanaşmanın əhəmiyyətindən bəhs edib. Forumda jurnalist Orxan Cabbarlının
                        moderatorluğu ilə panel də baş tutub. Bakı Şəhər Təhsil İdarəsində
                        Keyfiyyətə nəzarət sektorunun müdiri Anar Mustafazadə, 291 nömrəli Ekologiya
                        liseyinin direktoru Arif Əsədov, tarix üzrə fəlsəfə doktoru Elvin Əliyev və
                        təlimçi-psixoloq Fərid Seyfullayevin iştirakı ilə reallaşan paneldə uğurlu
                        təhsil konsepsiyaları, təhsildə idarəetmə strategiyası, məktəb
                        idarəetməsindəki yanlışlıqlar, yeni nəslə yeni təhsil, təhsilin onlayn
                        idarəedilməsi və rəqəmsallaşma, eləcə də metamən mövzuları ətrafında
                        bir-birindən maraqlı fikirlər səsləndirilib. Forum iştirakçıları da tədbirə
                        interaktivlik qatıb, sözügedən mövzularla bağlı məruzəçilərə bir-birindən
                        maraqlı suallar verib, dolğun cavablar alıblar. Əlavə məlumat üçün bildirək
                        ki, yüzlərlə təhsil işçisinin qatıldığı forumda Təhsilin İnkişafı Fondu
                        idarə heyətinin sədri Elnur Nəsibov, Elm və Təhsil Nazirliyi yanında ictimai
                        şuranın üzvü İlqar Orucov, Mədəniyyət Nazirliyi yanında ictimai şuranın üzvü
                        Əkbər Qoşalı, təhsil eksperti Kamran Əsədov, müxtəlif universitetlərin
                        rektorları, təhsil sahəsində çalışan mütəxəssislər, media, eləcə də
                        ictimaiyyət nümayəndələri iştirak ediblər. Forum Hədəf Şirkətlər Qrupunun
                        təşkilatçılığı ilə 20 nömrəli məktəbdə keçirilib. Sonda bütün iştirakçılara
                        sertifikat verilib. Tərəfdaşlar: “Hedef Group”, “Hədəf Global”, Hədəf STEAM
                        Liseyi, Dünya Məktəbi, “SİNKOD” yayın və dağıtım şirkəti, Mənəvi Dəyərlərin
                        Təbliği Fondu, Kipr Elm Universiteti, AzTV, EduPress, SİA, Hədəf Təlim və
                        Karyera Mərkəzi, Təhsil Forumu Platforması. #Tehsilmenecerleriforumu #TMF
                        #TəhsilMenecerləriForumu
                      </MKBox>
                    }
                  />
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </MKBox>
        <Pages />
        <Footer />
      </MKBox>
    </>
  );
}

export default BlogTwo;
